import React, { useState } from 'react'
import image from "../../assets/image1.jpg"
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { CgShapeCircle } from 'react-icons/cg';
import { BsRecordCircle } from 'react-icons/bs'
import MovingText from 'react-moving-text'
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import './home.css'

const ImageUrl = () => {
  const slides = [

    {
      url: 'https://images.pexels.com/photos/6195129/pexels-photo-6195129.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      title: "We Are Professional",
      subTitle: "Your space, our expertise – a winning combination.",
      buttonTitle: "Get a quote"

    },
    {
      url: 'https://images.pexels.com/photos/4098322/pexels-photo-4098322.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: "We Are Experts",
      subTitle: " We take the stress out of cleaning.",
      buttonTitle: "Get a quote"
    },
    {
      url: 'https://images.pexels.com/photos/4099471/pexels-photo-4099471.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      title: "We Are Trusted",
      subTitle: "The cleanest choice for your business.",
      buttonTitle: "Get a quote"
    },

    // {
    //   url: 'https://images.unsplash.com/photo-1512756290469-ec264b7fbf87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2253&q=80',
    //   name:"Surya"
    // },
    // {
    //   url: 'https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80',
    //   name:"Alwin"
    // },
  ];
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0)

  setTimeout(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex)
  }, 6000);

  const prevSlide = () => {
    const idFirstSlide = currentIndex === 0;
    const newIndex = idFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex)
  }
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex)
  }

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex)
  }

  return (
    <div className='max-w-[1900px] md:h-[90vh] h-[400px] w-full m-auto group relative '>
      <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        className=' w-full h-full rouned-2xl bg-center bg-cover duration-500 
      flex  flex-col justify-center items-center text-center  '>
        <div className='md:space-y-10 space-y-4'>
          <div className='text-white md:text-3xl text-lg font-medium md:py-0 mt-10 md:mt-0 '>
            <MovingText
              type="fadeInFromRight"
              duration="1000ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none">{slides[currentIndex]?.title}</MovingText>
          </div>
          <div className='text-white md:text-6xl text-3xl font-bold md:py-0 py-7 heading-fonts'>
            <MovingText
              type="fadeInFromLeft"
              duration="1000ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none">{slides[currentIndex]?.subTitle}</MovingText>
          </div>
          <div className='text-white '>
            <MovingText
              type="fadeInFromBottom"
              duration="1000ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none">
              <button onClick={() => navigate('contact-us')} type="button" className="inline-block md:px-10 px-4  md:py-4 py-2 border-2 border-white text-white font-medium 
                        leading-tight uppercase rounded hover:bg-[#000520] hover:bg-opacity-1 hover:text-white transition-all
                       duration-300 ease-in focus:outline-none focus:ring-0  
                      t  md:text-xl  ">
                {slides[currentIndex]?.buttonTitle}
              </button>
            </MovingText>
          </div>
          <div>
            <Stack display='flex' direction='row' spacing={5} justifyContent='center' alignItems='flex-end'>
              {slides.map((slide, slideIndex) => (
                <div className=''>
                  <div
                    key={slideIndex}
                    onClick={() => goToSlide(slideIndex)}
                    className='text-gray-100 cursor-pointer text-2xl md:text-4xl '>
                    {slideIndex === currentIndex? <BsRecordCircle />: <CgShapeCircle />}
                  </div>
                </div>
              ))}
            </Stack>
          </div>
        </div>
      </div>


      {/* left arrow */}
      {/* <div className='hidden group-hover:block absolute  top-[40%] -translate-x-0
        translate-y-[50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div> */}
      {/* right arrow */}
      {/* <div className='hidden group-hover:block absolute top-[40%] -translate-x-0 
       translate-y-[50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div> */}

    </div >
  )
}

export default ImageUrl