import LocationOnIcon from '@mui/icons-material/LocationOn';

export const LocationList = [
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'GTA',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'SCARBOROUGH',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'THORNHILL',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'AURORA',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'BRAMPTON',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'PICKERING',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'BOWMANVILLE',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'MIDLAND',
    },
     {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'MAPLE',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'NOBLETON',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'HAMILTON',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'KITCHENER',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'WINDSOR',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'INNISFIL',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'GEORGINA',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'BOLTON',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'TORONTO',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'ETOBICOKE',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'RICHMOND HILL',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'NEWMARKET',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'STOUFFVILLE',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'AJAX',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'KINGSTON ONTARIO',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'KING CITY',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'CONCORD',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'OAKVILLE ONTARIO',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'ST. CATHERINES',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'WATERLOO',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'CAMBRIDGE',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'BRADFORD',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'GWILLIMBURY',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'NORTH YORK',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'VAUGHAN',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'MARKHAM',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'MISSISSAUGA',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'PORT PERRY',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'OSHAWA',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'BARRIE',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'WOODBRIDGE',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'MILTON',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'BURLINGTON',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'NIAGARA',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'LONDON ONTARIO',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'GEORGETOWN ',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'ORANGEVILLE ',
    },
    {
        icon:<LocationOnIcon sx={{color:'blue'}}/>,
        place:'ALLISTON ',
    },
]