import React, { useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import MovingText from 'react-moving-text'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
// import Green from "../../assets/Green.png"
import Green5 from "../../assets/Green5.png"
import GMK_LOGO from "../../assets/GMK_LOGO.png"
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { LocationList } from '../../pages/Home/LocationList'
import './navbar.css'

const Navbar = () => {

  const [nav, setNav] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [specialOpen, setSpecialOpen] = useState(false)
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0)
  const navigate = useNavigate()

  const dropDownData = [
    { dropName: "Commercial", link: "commercial-cleaning" },
    { dropName: "Residental", link: "residential-cleaning" },
    { dropName: "Janitorial", link: "janitorial-service" },
    { dropName: "Industrial", link: "industrial-cleaning" },
  ];

  const firstOpen = () => {
    setIsOpen((prev) => !prev)
    setSpecialOpen(false)

  }

  const secondOpen = () => {
    setSpecialOpen((prev) => !prev)
    setIsOpen(false)
  }
  const DropClose = () => {
    setIsOpen(false)
    setSpecialOpen(false)
  }

  setTimeout(() => {
    if (currentIndex === LocationList.length) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex + 1)
    }
  }, 5000);


  return (
    <>
      <div className='shadow-md w-full sticky top-0 left-0 z-10 '>
        <div className='shadow-md w-full bg-[#FFFFFF] hidden md:block h-[60px] '>
          <div className='shadow-md w-full bg-[#FFFFFF] h-[45px]'>
            <Box mr={5}>
              <Box sx={{ mt: 1.5, display: { md: 'block', sm: 'none', xs: 'none', lg: 'block', } }}>
                <Stack direction="row" spacing={10} justifyContent="flex-end" >
                  <Box sx={{ mt: .2, width: '40%', }}>
                    <Stack display='flex' direction='row' spacing={2} >

                      <Box sx={{ mt: .2, color: 'blue' }}><PlaceIcon /></Box>
                      <Box>
                        {/* <MovingText
                type="slideOutToRight"
                duration="1000ms"
                delay="5s"
                direction="normal"
                timing="ease"
                iteration="infinite"
                fillMode="none"> */}
                        <Typography sx={{ mt: .4 }} fontWeight={600} fontSize={16} variant='h6'>{LocationList[currentIndex]?.place}</Typography>
                        {/* </MovingText> */}
                      </Box>

                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row" spacing={5}  >
                      <MailOutlineIcon />
                      <Link
                        onClick={() => window.location.href = 'mailto:info@greenmarkking.ca'}><Typography fontWeight={600} fontSize={17} variant='h6'>info@greenmarkking.ca</Typography>
                      </Link>
                    </Stack>
                  </Box>
                  <Box sx={{ width: '40%' }}>
                    <Stack direction="row" spacing={2}>
                      <PhoneIphoneIcon /> <Typography fontWeight={600} fontSize={17}> <a href='tel:+1 (416) 903-9498'>+1 (416) 903-9498</a></Typography>
                      <PhoneIcon /> <Typography fontWeight={600} fontSize={17}> <a href='tel:+1 (416) 272-9799'>+1 (416) 272-9799</a></Typography>
                    </Stack>
                  </Box>
                </Stack>

              </Box>
            </Box>
          </div>
          <div className='shadow-md w-full bg-[#000520] h-[10px] pt-20'>
          </div>
        </div>
        <div className='md:flex item-center justify-between bg-white py-4 md:py-2 md:px-6 px-7 relative hover:b-[#9AE6B4] transition duration-300 ease-in'>
          <div className='md:w-[332px] font-bold text-2xl cursor-pointer flex items-center 
         text-gray-700'>
            <span className='md:text-4xl text-gray-700 mr-1 md:px-1  pt-2'>
              {/* <ion-icon name="logo-ionic"></ion-icon> */}
              <Link to='/'> <img className='md:w-20 w-12' src={GMK_LOGO} alt="" /></Link>
            </span>
            <Link to='/'><div className=' text-black hover:text-[#9AE6B4] md:text-3xl text-xl font-semibold company-name'>
              Green Mark King
            </div></Link>
          </div>
          <div onClick={() => setOpen(!open)}
            // <div onClick={openClose} 
            className='text-3xl absolute right-8 top-6
              cursor-pointer md:hidden text-black '
          >
            <ion-icon name={open ? 'close' : "menu"}></ion-icon>
          </div>
          <ul className={`md:flex md:items-center md:pb-0 bg-white
           pb-12 absolute md:static  md:z-auto z-[]
            left-0 w-full md:w-auto md:pl-0 sm:pl-72 pl-8 transition-all
             duration-500 ease-in ${open ? "top-20" : "top-[-490px]"}`}>
            {/* {
              Links && Links.map((link) => (
                <MovingText
                type="fadeInFromLeft"
                duration="1000ms"
                delay="0s"
                direction="normal"
                timing="ease"
                iteration="1"
                fillMode="none">
                <li key={link.name} className="md:ml-8 sm:text-sm lg:text-lg md:my-0 my-7 text-white">
      
                   <NavLink onClick={openClose} to={link.link}>{link.name}</NavLink>
                </li>
                </MovingText>
              ))
            } */}
            <li className="flex md:ml-10 sm:text-sm lg:text-[1.12rem] md:my-0 my-7 text-black font-bold">
              <div className=" md:flex md:space-x-10 space-y-7  ">
                <Link to='/'><div onClick={() => setOpen(false)} className='md:my-7 text-[#000520] hover:text-[#9AE6B4]'>Home</div></Link>
                {/* FIRST DROPDOWN */}
                {/* <div className="" onClick={() => setIsOpen((prev) => !prev)} > */}
                <div onMouseEnter={firstOpen} onMouseLeave={DropClose}>
                  <div className="hover:text-[#9AE6B4] " >
                    <Box display='flex' flexDirection='row'>
                      <Box>Service</Box>
                      <Box mt={1}>< MdArrowDropDown /></Box>

                    </Box>


                  </div>
                  {isOpen &&
                    <div className='bg-[#000520] text-white absolute md:top-12 right top-0 mt-6 flex 
                      flex-col rounded p-2 w-[240px] md:w-[230px]
                       transition-all duration-500
                      '>
                      {dropDownData.map((item, index) => (
                        <div key={index}>
                          <Link to={item.link}>
                            <div onClick={() => setOpen(false)}>
                              <div className='hover:bg-green-400 cursor-pointer rounded 
                          transition-all duration-500 w-full flex justify-start px-5 py-3 '>
                                <h3 >{item.dropName}</h3>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>}
                </div>
                {/* SECOND DROPDOWN */}

                {/* <div onClick={() => setSpecialOpen((prev) => !prev)}> */}
                <div onClick={() => setOpen(false)} onMouseEnter={secondOpen} onMouseLeave={DropClose}>
                  <Link to='Special-service'><div className='hover:text-[#9AE6B4]' >
                    Special services
                    {!specialOpen ? (
                      ''
                    ) : ('')}
                  </div></Link>
                  <div className='md:relative md:right-70'>

                  </div>
                </div>
                <div onClick={() => setOpen(false)}>
                  <Link className='hover:text-[#9AE6B4]' to='about-us'>About</Link></div>
                <div onClick={() => setOpen(false)}>
                  <Link className='hover:text-[#9AE6B4]' to='contact-us'>
                    Contact us
                  </Link>
                </div>
              </div>
            </li>
            <Button data={"+1 (416) 272-9799"} />
            <Box sx={{display:{xs:'block', md:'none', sm:'none'},mt:2}}>
              <Button data={"+1 (416) 903-9498"} />
            </Box>
          </ul>
        </div>
      </div>
    </>
  )

}


export default Navbar