import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { Container, createTheme, Divider, Grid, Stack, Typography } from '@mui/material';
import image from '../../../assets/Images/Commercial.jpg'
import { ThemeProvider } from '@mui/system';
import MovingText from 'react-moving-text'
import { CgShapeCircle } from 'react-icons/cg';
import { BsRecordCircle } from 'react-icons/bs'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';


const theme = createTheme();

theme.typography.h7 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
};

// Common areas cleaning
// Vacuuming
// Sweeping and mopping
// Dusting and detailing of all surface and fixtures
// Restrooms and shower cleaning
// Vacuuming
// Kitchen, breakroom cleaning
// Reception, lobbies and hallways cleaning
// Meeting spaces, Boardroom cleaning and much more.

const Data = [
  {
    title1: 'Common areas cleaning',
    title2: 'Vacuuming',
    image1: 'https://images.pexels.com/photos/4867360/pexels-photo-4867360.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    image2: 'https://images.pexels.com/photos/9462139/pexels-photo-9462139.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 ',
    content1: 'Our dedicated personnel help in keeping your most visited areas clean and tidy with no trace of any dirt left behind. We employ different techniques in cleaning up your facility according to your demands. Our assistance broadens to reception, lobbies, hallway cleaning, meeting spaces, boardroom cleaning, and much more.',
    content2: 'With our cutting-edge technology, we vacuum all parts of your space, including carpets, hallways, and balconies, achieving an antiseptic domain.    ',
  },
  {
    title1: 'Sweeping and mopping',
    title2: 'Dusting and detailing of all surface and fixtures',
    image1: 'https://images.pexels.com/photos/5591861/pexels-photo-5591861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    image2: 'https://images.pexels.com/photos/4108712/pexels-photo-4108712.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    content1: 'Green Mark King gives you a place free of any dirt or marking with the usage of biodegradable products. Our staff ensures the floors and premises are clean and appealing that meet up your expectation. All products are formulated with environmentally friendly surfactants, and we also have strong solvents prepared to remove all tough jobs.',
    content2: 'Dusting every other week might make you miserable. Place faith in Green Mark King we can give you a dust-free and sparkling finished space with no dust behind in any of the areas. We remove clutter, dust, crumbs, and other detritus.    ',
  },
  {
    title1: 'Restrooms and shower cleaning',
    title2: 'Kitchen, breakroom cleaning',
    image1: 'https://images.pexels.com/photos/3867601/pexels-photo-3867601.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 ',
    image2: 'https://images.pexels.com/photos/10573242/pexels-photo-10573242.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    content1: 'Maintaining a clean and safe kitchen is a significant responsibility to reduce the risk exposure lures to many contaminants. We do deep cleaning, filter cleaning, and floor cleaning employing specified cleaning disinfectants or liquids, abrasives, degreasers, etc. Keep your kitchen in perpetual tip-top shape by choosing Green Mark King',
  },
  {
    title1: 'Window Cleaning',
    image1: 'https://images.pexels.com/photos/9462100/pexels-photo-9462100.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    content1: 'Green Mark King has the expertise in giving your windows a flawless texture cleared of any dust, debris, fingerprints, and streaks. We utilize window-specific cleaning methodology and specialized chemical solutions to keep the glass doors glassy.    ',
  },
]

export default function Janitorial() {

  const slides = [

    {
      url: 'https://images.pexels.com/photos/4108715/pexels-photo-4108715.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: "Janitorial Cleaning",
      subTitle: " Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
      buttonTitle: "Get a quate"
    },
    {
      url: 'https://images.pexels.com/photos/6195122/pexels-photo-6195122.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: "Janitorial Cleaning",
      subTitle: "  Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
      buttonTitle: "Get a quate"
    },
    {
      url: 'https://images.pexels.com/photos/6197111/pexels-photo-6197111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: "Janitorial Cleaning",
      subTitle: " Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
      buttonTitle: "Get a quate"
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0)

  const prevSlide = () => {
    const idFirstSlide = currentIndex === 0;
    const newIndex = idFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex)
  }
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex)
  }
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex)
  }

  return (
    <div>
      {/* <Box sx={{ mt: 8, ml: 6, mr: 6, pb: 5 }}>

        <Grid item md={12} sm={12} xs={2}>
          <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
            <Box display='flex' justifyContent='center'>
              <Typography variant='h3' fontWeight='bold'><div className='heading-fonts'>JANITORIAL CLEANING</div></Typography>
            </Box>
          </MovingText>
        </Grid>
      </Box> */}
      <Box sx={{ ml: 2, mr: 2, maxHeight: '100%' }} >

        {/* //CAROUSAL// */}

        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
          <div className='max-w-[1900px] md:h-auto h-[400px] w-full group relative '>
            <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
              className=' w-full h-[500px] md:h-[632px] xs:h-[450px] rouned-2xl bg-center bg-cover duration-500 
              flex  flex-col justify-center items-center text-center  '>
              <div className='md:space-y-10 space-y-4'>
                <div className='text-white md:text-6xl text-3xl font-bold md:py-0 py-7 heading-fonts'>
                        <MovingText
                            type="fadeInFromLeft"
                            duration="1000ms"
                            delay="0s"
                            direction="normal"
                            timing="ease"
                            iteration="1"
                            fillMode="none">{slides[currentIndex]?.title}</MovingText>
                    </div>
                    {/* <div className='text-white md:text-2xl text-1sm font-medium md:py-0 mt-10 md:mt-0 '>
                        <MovingText
                            type="fadeInFromRight"
                            duration="1000ms"
                            delay="0s"
                            direction="normal"
                            timing="ease"
                            iteration="1"
                            fillMode="none">{slides[currentIndex]?.subTitle}</MovingText>
                    </div> */}
                <div className='text-white '>
                  <MovingText
                    type="fadeInFromBottom"
                    duration="1000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="1"
                    fillMode="none">
                    {/* <button type="button" className="inline-block md:px-10 px-4  md:py-4 py-2 border-2 border-white text-black font-medium 
                        leading-tight uppercase rounded hover:bg-white hover:bg-opacity-1 hover:text-black transition-all
                       duration-300 ease-in focus:outline-none focus:ring-0  
                      t  md:text-xl  ">
                                {slides[currentIndex]?.buttonTitle}
                            </button> */}
                  </MovingText>
                </div>

                <div>
                  <Box sx={{ pt: 15 }}>
                    <Stack display='flex' direction='row' spacing={5} justifyContent='center' alignItems='flex-end'>
                      {slides.map((slide, slideIndex) => (
                        <div className=''>
                          <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className='text-gray-100 cursor-pointer text-2xl md:text-4xl '>
                            {slideIndex === currentIndex ? <BsRecordCircle /> : <CgShapeCircle />}
                          </div>
                        </div>
                      ))}
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>

            {/* left arrow */}

            {/* <div className='hidden group-hover:block absolute  top-[40%] -translate-x-0 translate-y-[50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
              <BsChevronCompactLeft onClick={prevSlide} size={30} />
            </div> */}

            {/* right arrow */}

            {/* <div className='hidden group-hover:block absolute top-[40%] -translate-x-0 translate-y-[50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
              <BsChevronCompactRight onClick={nextSlide} size={30} />
            </div> */}
          </div>

        </MovingText>

        {/* //CAROUSAL// */}

        <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '20vh', mt: 14, backgroundColor: 'black', display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Professional,veted cleaners</Typography>
              </Grid>,mt:5
              <Grid item xs>
                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Friendly customer support</Typography>
              </Grid>
              <Grid item xs>
                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Bonded and insured</Typography>
              </Grid>
              <Grid item xs>
                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Recurring discounts</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Container maxWidth="lg" sx={{ mt: 10, maxHeight: '200vh', mt: 17 }}>
          <Box sx={{ maxHeight: '200vh' }} >
            <Typography fontWeight={400} variant='h6' sx={{opacity:.6}}>
              fast-paced professional team for a wide range of industrial complexes tailored in consonance
              with the needs of your plant. Our Company has the most certified team of cleaners in the Ontario
              region providing comprehending cleaning services necessary for large-scale industrial cleaning
              projects. The cleaners are our own employees, so you can count on them to provide you with excellent service and committed
              after-service support. From deep disinfection to basic cleaning, we can design the perfect plan to maintain your facility's c
              leanliness to the high standards you desire.
            </Typography>
          </Box>
        </Container>

        <Box sx={{ mt: 6 }}>
          <Grid item md={8} sm={12} xs={12}>
            <Container maxWidth="lg" sx={{ mt: 10 }}>
              <Box display='flex' justifyContent='center' sx={{ maxHeight: '50vh', pl: 6, pr: 6, width: '100%', backgroundColor: '#000520' }}>
                <Typography sx={{ pt: 6, pb: 6, color: '#FFFFFF' }} fontWeight={600} variant='h5'>
                  We extend our hand in these standard elements
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        {Data.map((item, index) => (
          <Box key={index}>
            <Box minHeight='60vh' sx={{ mt: 12, boxShadow: 3 ,borderRadius:5 }} display='flex' justifyContent='center' alignItems='center'>
              <Box sx={{ mt: 2, maxHeight: '100%', ml: 3, mr: 3 }} >
                <Grid container spacing={2}>
                  <Grid item md={4} sm={12} xs={12}>
                    <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                      <Box
                        sx={{
                          width: '100%',
                          height: 300,
                        }}
                      >
                        <img className='Sub-Image' src={item.image1} />
                      </Box>
                    </MovingText>
                  </Grid>
                  <Grid item md={8} xs={12} sm={12}>
                    <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                      <Box
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100vh'
                        }}
                      >
                        <Typography sx={{ mt: 2, ml: 2, }} fontWeight={600} variant="h3" gutterBottom>
                          <div className='heading-fonts text-[#9AE6B4]'>{item.title1}</div>
                        </Typography>
                        <Box sx={{ height: '100%', ml: 2, mr: 2, }}>
                          <ThemeProvider theme={theme}>
                            <Typography  variant="h7" sx={{ maxWidth: '100%',opacity:.6 }}  >
                              {item.content1}
                              {/* Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres */}
                            </Typography>

                          </ThemeProvider>
                        </Box>
                      </Box>
                    </MovingText>
                  </Grid>
                </Grid>
              </Box>


            </Box>
            {item.content2 && item.image2 ?
              <Box sx={{ mt: 8,  minHeight: '60vh',boxShadow:3 ,borderRadius:5 }} >
                <Box sx={{ mt: 2, maxHeight: '100%', ml: 3, mr: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item md={8} xs={12} sm={12}>
                      <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                        <Box
                          sx={{
                            maxWidth: '100%',
                            maxHeight: '100vh'
                          }}
                        >
                          <Typography sx={{ mt: 2, ml: 2, }} fontWeight={600} variant="h3" gutterBottom>
                            <div className='heading-fonts text-[#9AE6B4]'> {item.title2}</div>
                          </Typography>
                          <Box sx={{ height: '100%',ml: 2, mr: 2,  }}>
                            <ThemeProvider theme={theme}>
                              <Typography variant="h7" sx={{ maxWidth: '100%',opacity:.6 }}  >
                                {item.content2}
                                {/* Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres */}
                              </Typography>
                            </ThemeProvider>
                          </Box>
                        </Box>
                      </MovingText>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                        <Box
                          sx={{
                            width: '100%',
                            height: 300,
                          }}
                        >
                          <img className='Sub-Image' src={item.image2} />
                        </Box>
                      </MovingText>
                    </Grid>
                  </Grid>
                  {/* <Divider sx={{ pt: 10 }} /> */}
                </Box>
                </Box>  : ''}
              </Box>

        ))}
          </Box>
    </div >
  )
}
