import React from 'react'
import ImageUrl from './ImageUrl'
import { FaPeopleCarry } from 'react-icons/fa';
import { BiBuildingHouse } from 'react-icons/bi';
import { FiPhoneCall } from 'react-icons/fi';
import MovingText from 'react-moving-text'
import Footer from '../../Components/Footer/Footer';
import ServiceProvider from './ServiceProvider';
import './home.css'
import ChooseUs from './ChooseUs';
import ServiceProvides from './ServiceProvides';
import Locations from './Locations';
import { Box } from '@mui/system';
import OurClients from './clients/OurClients';

// import Experience from './Experience';


function Home() {

    return (
        <div> {/* <div className='  w-screen p-3 px-4 md:px-16 bg-primary'> */}
            <div className=''>
                <ImageUrl /> {/* </div> */} </div>

            {/* UPPER MIDDLE BAR */}
            <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none ">
                <div className='grid md:grid-cols-3 md:h-[260px] h-[740px]  pb-10'>

                    <div className=' bg-[#9AE6B4] h-[300px] flex justify-center items-center text-white  '>

                        <div className='px-5 md:px-10 text-white mt-[60px]'>
                            <FaPeopleCarry className=' text-text-white '
                                size={70} />
                        </div>
                        <div className='space-y-10'>
                            <div className='text-2xl font-semibold text-white heading-fonts'>
                                EXPERT WORKER
                            </div>
                            <div className=''>
                                <h1 className='text-xl text-white font-semibold'>Our team of expert cleaning workers are highly skilled and
                                    experienced professionals.</h1>
                            </div>
                        </div>

                    </div>

                    <div className=' bg-[#000520] h-[300px] flex justify-center items-center text-white '>

                        <div className='px-5 text-white mt-[60px]'>
                            <BiBuildingHouse size={70} />
                        </div>
                        <div className='space-y-10'>
                            <div className='text-2xl font-semibold text-white heading-fonts '>
                                Quality Work
                            </div>
                            <div>
                                <h1 className='text-xl text-white font-semibold'>At Green Mark King, we are dedicated to delivering the highest
                                    quality work to our clients.</h1>
                            </div>
                        </div>

                    </div>
                    {/* <div className='bg-[#000520] flex justify-center items-center text-white'>Ajay</div> */}
                    <div className=' bg-[#9AE6B4] h-[300px] flex justify-center items-center text-white '>

                        <div className='px-5 text-white mt-[60px]'>
                            <FiPhoneCall className=' text-white '
                                size={70} />
                        </div>
                        <div className='space-y-10'>
                            <div className='text-2xl font-semibold text-white heading-fonts '>
                                24/7 Support
                            </div>
                            <div>
                                <h1 className='text-xl text-white font-semibold'>we offer 24/7 support to ensure that our clients always have
                                    someone to turn to for help.</h1>
                            </div>
                        </div>

                    </div>
                </div>
            </MovingText>


            {/* EXPERIENECE FIlE */}

            <div className='md:pt-36 pt-60 grid md:grid-cols-2     bg-cover'>
                {/* <div className='space-y-7 md:space-y-0'> */}
                <div className='  bg-green-500  '>
                    <img className='w-full h-full object-cover  ' src="https://images.pexels.com/photos/7218568/pexels-photo-7218568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />

                </div>
                {/* <div>hekllo</div> */}

                <div className='flex flex-col md:justify-center items-start md:px-8 md:col-span- md:space-y-12 space-y-4 bg-[#000520]'>
                    <div className='space-y-10 md:spacey-0 py-16'>
                        <div className='space-y-2 px-9 md:px-24'>
                            <h1 className=' md:text-2xl text-lg py-3  font-medium text-white'>Welcome to
                                <span className='text-[#9AE6B4]'> Green Mark King</span>
                            </h1>
                            <h1 className='text-4xl font-bold text-white heading-fonts'>20 Years of <span className='text-[#9AE6B4] heading-fonts'>Excellence</span>
                            </h1>
                        </div>
                        <div className='py-3 md:py-0 px-9 md:px-24'>
                            <p className='  bg-reen-500 text-s text-white text-lg '>
                                Green Mark King is a professional cleaning service company dedicated to providing top-quality
                                commercial, residential, janitorial, and industrial cleaning services. Our team is highly trained
                                and experienced in a wide range of cleaning techniques and technologies, ensuring that we can
                                handle any cleaning challenge that comes our way.
                                <br />
                                <br />
                                We take pride in our attention to detail and commitment to customer
                                satisfaction. When you choose Green Mark King for your cleaning needs,
                                you can trust that we will go above and beyond to deliver the highest
                                level of cleanliness and hygiene. Whether you need one-time cleaning
                                services or ongoing maintenance, we have the expertise and resources
                                to get the job done right.
                            </p>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>

            {/* <div className='md:pt-36 pt-60'>
            <img src="https://images.unsplash.com/photo-1512756290469-ec264b7fbf87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2253&q=80"
            className='xl:w-1/2 xl:float-left md:px-8 px-4 lg:w-1/2  lg:float-right md:w-full '
            alt="" />
            <p className='xl:w-1/2 py-8 px-8 leading-loose tracking-widest mx-auto xl:text-lg xl:float-right lg:w-1/2
            lg:float-left lg:text-sm
            '>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                 Ab dolorem consectetur, id neque, impedit ipsam architecto 
                 amet illum ipsa autem molestiae.Laboriosam libero, ad esse 
                 magnam cumque ipsa dignissimos corrupti!
            </p>
          </div> */}

            {/* SERVICE PROVIDERS */}

            <ServiceProvides />

            <ChooseUs />

            {/* LOCATIONS */}

            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'block' } }}>
                <Locations />
            </Box>

            {/* CLIENTS */}

            <Box>
                <OurClients />
            </Box>

        </div>
    )
}

export default Home
