import React, {useEffect, useState} from 'react'
import image1 from "../../assets/image1.jpg"
import MovingText from 'react-moving-text'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Contact() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [date, setDate] = useState("")

    // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`

    useEffect(() => {
        const datee = new Date().toLocaleString()
        setDate(datee)

    },[])
    console.log(date);


    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("axios data",date);
        const data = {
            Name: name,
            Email: email,
            Phone: phone,
            Message: message,
            Date: date
        }
        axios.post('https://sheet.best/api/sheets/82bbda08-fd60-4256-8bb7-18380276b730', data).then(response => {
            console.log(response);
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
           
        }, toast("Succefully submitted"))
    }

    return (
        <div> {/* image div */}
            <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">

                <div className=' hidden  md:block lg:block  '>
                    <img src='https://images.pexels.com/photos/6195120/pexels-photo-6195120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' className="  w-full h-[500px] object-cover
                                            rouned-2xl bg-center bg-cove duration-500 flex flex-col justify-center items-center text-center" alt="..."/>
                    <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">

                        <div className='absolute top-72 left-24'>
                            <h1 className=' text-white drop-shadow-2xl stroke-black font-semibold text-4xl uppercase heading-fonts'>Green Mark King</h1>
                        </div>
                    </MovingText>
                </div>
            </MovingText>


            <div className='z-50  p-3 px-4 md:px-16  '>
                <div className='md:mt-[30px]'>
                    <div className='flex w-full min-h-full justify-center items-center bg-white'>
                        <div className=' flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0  bg-cyan-00 w-full
                                                             p-8  rounded-xl shadow-lg text-white overflow-hidden'>
                            {/* contact Information */}

                            <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                                <div className='flex flex-col space-y-8 justify-between'>
                                    <div className='space-y-4 max-w-8xl'>
                                        <h1 className='font-bold text-4xl tracking-wide md:gap-4 text-[#9AE6B4] heading-fonts'>About Us</h1>
                                        <p className='pt-2 text-cyan-900 text-lg  '>We take pride in our attention to detail and commitment to
                                                                  customer satisfaction.When you choose Green Mark King for
                                                                  your cleaning needs, you can trust that we go above and
                                                                  beyond to deliver the highest level of cleanliness and
                                                                  hygiene.

                                        </p>

                                        <p className='text-cyan-900  text-lg'>
                                            Whether you need one-time cleaning services or ongoing
                                                                  maintanance, we have the expertise and resources to Get
                                                                  the job done right.
                                        </p>
                                    </div>
                                    <h1 className='font-bold text-4xl tracking-wide md:gap-4 text-[#9AE6B4] heading-fonts'>Contact Us</h1>
                                    <div className='flex flex-col space-y-6'>
                                        <div className='inline-flex space-x-2 items-center text-cyan-700'>

                                            {/* <span className='text-teal-300 text-2xl'> */}
                                            <ion-icon name="call"></ion-icon>
                                            {/* </span> */}
                                            <span className='text-cyan-900'>+(905) 714-2593</span>
                                        </div>
                                        <div className='inline-flex space-x-2 items-center text-cyan-700'>
                                            {/* <span className='text-teal-300 text-2xl'> */}
                                            <ion-icon name="mail-unread"></ion-icon>
                                            {/* </span> */}
                                            <span className='text-cyan-900'>info@greenmarkking.ca</span>
                                        </div>
                                        <div className='inline-flex space-x-2 items-center text-cyan-700'>
                                            {/* <span className='text-teal-300 text-2xl'> */}
                                            <ion-icon name="location"></ion-icon>
                                            {/* </span> */}
                                            <span className='text-cyan-900'>138 Shorting Rd Scarborough On M1S3S6</span>
                                        </div>
                                    </div>
                                    <div className='flex space-x-4 lg:text-3xl md:text-2xl text-lg'>
                                        <a href="#" className='text-gray-700'>
                                            <ion-icon name="logo-facebook" className=""></ion-icon>
                                        </a>
                                        <a href="#" className='text-gray-700'>
                                            <ion-icon name="logo-twitter"></ion-icon>
                                        </a>
                                        <a href="#" className='text-gray-700'>
                                            <ion-icon name="logo-linkedin"></ion-icon>
                                        </a>
                                        <a href="#" className='text-gray-700'>
                                            <ion-icon name="logo-instagram"></ion-icon>
                                        </a>
                                    </div>
                                </div>
                            </MovingText>

                            {/* contact Form */}

                            <div className=' lg:w-50 md:w-50  relative z-0'>
                                <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                                    <div className='absolute z-0 w-40 h-40 bg-teal-400 rounded-full -right-28 -top-28 '></div>
                                    <div className='absolute z-0 w-40 h-40 bg-teal-400 rounded-full -left-28 -bottom-16  '></div>
                                    <div className=" relative z-10 bg-white rounded-xl shadow-lg p-8 text-gray-600 space-y-4 ">
                                        <div className=' space-y-4 '>
                                            <h1 className='font-bold text-xl tracking-wide md:gap-4 text-[#9AE6B4] heading-fonts'>Make An Enquiry</h1>
                                        </div>
                                        <form action="" className='flex flex-col space-y-4'
                                            onSubmit={handleSubmit}>
                                            <div>
                                                <label className='text-sm'>Your Name</label>

                                                <input type="text" autoFocus="autofocus" required placeholder="Your name" className='ring-1 ring-gray-300 caret-black w-full mt-2 rounded-md px-4 py-2 outlin-none
                                                                                                          focus:ring-2 focus:ring-[#9AE6B4] outline-none '
                                                    onChange={
                                                        (e) => setName(e.target.value)
                                                    }
                                                    value={name}/>
                                            </div>
                                            <div>
                                                <label className='text-sm'>Email Address</label>

                                                <input type="email" required placeholder="Email Address" className='ring-1 ring-gray-300 mt-2 caret-black w-full rounded-md px-4 py-2 outlin-none
                                                                                                          focus:ring-2 focus:ring-[#9AE6B4] outline-none '
                                                    onChange={
                                                        (e) => setEmail(e.target.value)
                                                    }
                                                    value={email}/>

                                            </div>
                                            <div>
                                                <label className='text-sm'>Phone Number</label>

                                                <input type="number" required placeholder="Phone number" className='ring-1 ring-gray-300 mt-2 caret-black w-full rounded-md px-4 py-2 outlin-none
                                                                                                         focus:ring-2 focus:ring-[#9AE6B4] outline-none '
                                                    onChange={
                                                        (e) => setPhone(e.target.value)
                                                    }
                                                    value={phone}/>

                                            </div>
                                            <div>
                                                <label className='text-sm'>Message</label>

                                                <textarea type="number" required placeholder="message" rows="4" className='ring-1 ring-gray-300 caret-black mt-2 w-full rounded-md px-4 py-2 outlin-none
                                                                                                          focus:ring-2 focus:ring-[#9AE6B4] outline-none '
                                                    onChange={
                                                        (e) => setMessage(e.target.value)
                                                    }
                                                    value={message}></textarea>

                                            </div>
                                            <button className='inline-block self-end bg-[#9AE6B4] text-white
                                                                                                    font-bold text-lg rounded-lg px-14 py-2 tracking-wider uppercase'>
                                                Send
                                            </button>
                                        </form>
                                    </div>
                                </MovingText>
                            </div>

                        </div>

                        {/* </div> */} </div>
                    {/* </div> */} </div>
            </div>
            <ToastContainer/>
            <script src="https://unpkg.com/ionicons@4.5.10-0/dist/ionicons.js"></script>


        </div>
    )
}

export default Contact
