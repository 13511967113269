import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { Container, createTheme, Divider, Grid, Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import MovingText from 'react-moving-text'
import { CgShapeCircle } from 'react-icons/cg';
import { BsRecordCircle } from 'react-icons/bs'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';


const theme = createTheme();

theme.typography.h7 = {
    fontSize: '1rem',
    '@media (min-width:600px)': {
        fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem',
    },
};



const Data = [
    {
        title1: 'Floor waxing:',
        title2: 'Carpet Cleaning',
        image1: 'https://cleanproservicesllc.com/wp-content/uploads/2018/12/floor-stripping.jpg',
        image2: 'https://images.pexels.com/photos/3867613/pexels-photo-3867613.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        content1: 'Floor waxing is the most integrated and detailed process providing you with quality waxing floor services. Step your foot to a glossy and shining floor with our Green Mark’s cleaning assistance. Our process starts right from cleaning your floor to coating a fresh layer of wax to stripped floors. Our work adds sheen to your floor which can brighten the space and give it a squeaky smooth finish. Our technology provides you with a guaranteed service without damaging your floors. Instead, we give you a complete package of makeover alternatives for your floors. ',
        content2: 'Do your carpets appear worn and stained? Are you unable to eliminate the dirt even after vacuuming? Now the problem is solved. Green Mark King promises you supreme quality carpet cleaning. Our process takes in the latest equipment that finishes off the work within a short period with great precision and cleanliness. Whatever the material is we use standards and certified fabric protectors for your carpets to make them more stain and filth-resistant. We can restore the original quality of your carpet with more shine and appealing nature.',
    },
    {
        title1: 'Deep Cleaning',
        title2: 'Power Washing',
        image1: 'https://images.pexels.com/photos/10324437/pexels-photo-10324437.jpeg',
        image2: 'https://peterspressurewashing.com/wp-content/uploads/2018/10/pressure-washing-equipment-e1541640152478.jpg',
        content1: 'Are you seeking a service that offers deep cleaning? Green Mark King is right near you focusing on nooks and crannies giving a sparkling space. Our team is well-trained and dedicated to ensuring your private space is an entreating visual. With the standard protocol, we focus on cleaning hard-to-reach areas that may not get frequent supervision. We come up with a cleaning policy that is a complete and routine process of removing dirt, germs, and stains rendering a professional touch to all your facility. We give you extra attention in often neglected areas furnishing a spotless and hygienic zone..​ ',
        content2: 'Green Mark King provides you with dependable and reliable power washing service across Ontario wide. Our high-pressure and steam-blasting technology can create spectacular external modifications without significant expense to your facility. We deal with our clients with a permanent solution to your worries with our expertise in cleaning. This is a heavy-duty option and we Green Mark King evince you the power effortlessly in removing unwanted matters in required areas. Trust us to give you a new place with an ultra-finished and polished surface.    ',
    },
]

function SpecialServices() {

    const slides = [          // <= CAROUSAL SLIDES  IMAGES ONLY USED//

        {
            url: 'https://images.pexels.com/photos/713297/pexels-photo-713297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
            title: "Special Services",
            subTitle: " Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
            buttonTitle: "Get a quate"
        },
        {
            url: 'https://images.pexels.com/photos/6196582/pexels-photo-6196582.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
            title: "Special Services",
            subTitle: "  Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
            buttonTitle: "Get a quate"
        },
        {
            url: 'https://images.pexels.com/photos/6197111/pexels-photo-6197111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
            title: "Special Services",
            subTitle: " Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
            buttonTitle: "Get a quate"
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0)

    const prevSlide = () => {
        const idFirstSlide = currentIndex === 0;
        const newIndex = idFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex)
    }
    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)
    }
    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex)
    }


    return (
        <div>
            {/* <Box sx={{ mt: 8, ml: 6, mr: 6 }}>

                <Grid item md={12} sm={12} xs={2}>
                    <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                        <Box display='flex' justifyContent='center'>
                            <Typography variant='h3' fontWeight='bold'> <div className='heading-fonts'>Special Services</div></Typography>
                        </Box>
                    </MovingText>
                </Grid>
            </Box> */}
            <Box sx={{ ml: 2, mr: 2, maxHeight: '100%' }} >

                {/* //   CAROUSAL // */}

                <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                    <div className='max-w-[1900px] md:h-auto h-[400px] w-full group relative '>
                        <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
                            className=' w-full h-[500px] md:h-[632px] xs:h-[450px] rouned-2xl bg-center bg-cover duration-500 
              flex  flex-col justify-center items-center text-center  '>
                            <div className='md:space-y-10 space-y-4'>
                                <div className='text-white md:text-6xl text-3xl font-bold md:py-0 py-7 heading-fonts '>
                                    <MovingText
                                        type="fadeInFromLeft"
                                        duration="1000ms"
                                        delay="0s"
                                        direction="normal"
                                        timing="ease"
                                        iteration="1"
                                        fillMode="none">{slides[currentIndex]?.title}</MovingText>
                                </div>
                                {/* <div className='text-white md:text-2xl text-1sm font-medium md:py-0 mt-10 md:mt-0 '>
                                    <MovingText
                                        type="fadeInFromRight"
                                        duration="1000ms"
                                        delay="0s"
                                        direction="normal"
                                        timing="ease"
                                        iteration="1"
                                        fillMode="none">{slides[currentIndex]?.subTitle}</MovingText>
                                </div> */}
                                <div className='text-white '>
                                    <MovingText
                                        type="fadeInFromBottom"
                                        duration="1000ms"
                                        delay="0s"
                                        direction="normal"
                                        timing="ease"
                                        iteration="1"
                                        fillMode="none">
                                        {/* <button type="button" className="inline-block md:px-10 px-4  md:py-4 py-2 border-2 border-white text-black font-medium 
                        leading-tight uppercase rounded hover:bg-white hover:bg-opacity-1 hover:text-black transition-all
                       duration-300 ease-in focus:outline-none focus:ring-0  
                      t  md:text-xl  ">
                                {slides[currentIndex]?.buttonTitle}
                            </button> */}
                                    </MovingText>
                                </div>

                                <div>
                  <Box sx={{ pt: 15 }}>
                    <Stack display='flex' direction='row' spacing={5} justifyContent='center' alignItems='flex-end'>
                      {slides.map((slide, slideIndex) => (
                        <div className=''>
                          <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className='text-gray-100 cursor-pointer text-2xl md:text-4xl '>

                            {slideIndex === currentIndex ? <BsRecordCircle /> : <CgShapeCircle />}
                          </div>
                        </div>
                      ))}
                    </Stack>
                  </Box>
                </div>
                            </div>
                        </div>

                        {/* left arrow */}

                        {/* <div className='hidden group-hover:block absolute  top-[40%] -translate-x-0 translate-y-[50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                            <BsChevronCompactLeft onClick={prevSlide} size={30} />
                        </div> */}

                        {/* right arrow */}

                        {/* <div className='hidden group-hover:block absolute top-[40%] -translate-x-0 translate-y-[50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                            <BsChevronCompactRight onClick={nextSlide} size={30} />
                        </div> */}
                    </div>

                </MovingText>

                {/* //CAROUSAL// */}

                <Container maxWidth="lg" sx={{ mt: 8, maxHeight: '200vh', mt: 17 }}>
                    <Box sx={{ maxHeight: '200vh' }} >
                        <Typography fontWeight={600} variant='h5' sx={{ opacity: .6 }}>
                            With 20 years of experience and 24-hour flexible timings, our Green Mark King cleaning services will make your space more appealing and inviting. We deliver specialized services to safeguard your identity and safety for everyone accepting certified chemicals as per your business needs.
                        </Typography>
                    </Box>
                </Container>


                <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '20vh', mt: 14, backgroundColor: 'black', display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>


                    <Box>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Professional,veted cleaners</Typography>
                            </Grid>,mt:5
                            <Grid item xs>
                                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Friendly customer support</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Bonded and insured</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Recurring discounts</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box sx={{ mt: 6 }}>
                    <Grid item md={8} sm={12} xs={12}>
                        <Container maxWidth="lg" sx={{ mt: 10 }}>
                            <Box sx={{ maxHeight: '50vh', pl: 6, pr: 6, width: '100%', backgroundColor: '#000520' }}>
                                <Typography sx={{ pt: 6, pb: 6, color: '#FFFFFF' }} fontWeight={600} variant='h5'>
                                    We understand a healthy and clean workplace foster a productive workplace. Our service extends to
                                </Typography>
                            </Box>
                        </Container>
                    </Grid>
                </Box>

                {Data.map((item, index) => (
                    <Box key={index}>
                        <Box minHeight='60vh' sx={{ mt: 12, boxShadow: 3, borderRadius: 5 }} display='flex' justifyContent='center' alignItems='center'>
                            <Box sx={{ mt: 2, maxHeight: '100%', ml: 3, mr: 3 }} >
                                <Grid container spacing={2}>
                                    <Grid item md={4} sm={12} xs={12}>
                                        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: 300,
                                                }}
                                            >
                                                <img className='Sub-Image' src={item.image1} />
                                            </Box>
                                        </MovingText>
                                    </Grid>
                                    <Grid item md={8} xs={12} sm={12}>
                                        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                                            <Box
                                                sx={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100vh'
                                                }}
                                            >
                                                <Typography sx={{ mt: 2, ml: 2, }} fontWeight={600} variant="h3" gutterBottom>
                                                    <div className='heading-fonts text-[#9AE6B4]'>{item.title1}</div>
                                                </Typography>
                                                <Box sx={{ height: '100%', ml: 2, mr: 2, }}>
                                                    <ThemeProvider theme={theme}>
                                                        <Typography variant="h7" sx={{ maxWidth: '100%', opacity: .6 }}  >
                                                            {item.content1}
                                                            {/* Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres */}
                                                        </Typography>

                                                    </ThemeProvider>
                                                </Box>
                                            </Box>
                                        </MovingText>
                                    </Grid>
                                </Grid>
                            </Box>


                        </Box>
                        <Box sx={{ mt: 8, minHeight: '60vh', boxShadow: 3, borderRadius: 5 }} >
                            <Box sx={{ mt: 2, maxHeight: '100%', ml: 3, mr: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item md={8} xs={12} sm={12}>
                                        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                                            <Box
                                                sx={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100vh'
                                                }}
                                            >
                                                <Typography sx={{ mt: 2, ml: 2, }} fontWeight={600} variant="h3" gutterBottom>
                                                    <div className='heading-fonts text-[#9AE6B4]'>{item.title2}</div>
                                                </Typography>
                                                <Box sx={{ height: '100%', ml: 2, mr: 2, }}>
                                                    <ThemeProvider theme={theme}>
                                                        <Typography variant="h7" sx={{ maxWidth: '100%', opacity: .6, }}  >
                                                            {item.content2}
                                                            {/* Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres */}
                                                        </Typography>
                                                    </ThemeProvider>
                                                </Box>
                                            </Box>
                                        </MovingText>
                                    </Grid>
                                    <Grid item md={4} sm={12} xs={12}>
                                        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: 300,
                                                }}
                                            >
                                                <img className='Sub-Image' src={item.image2} />
                                            </Box>
                                        </MovingText>
                                    </Grid>
                                </Grid>
                                {/* <Divider sx={{ pt: 10 }} /> */}
                            </Box>
                        </Box>
                    </Box>

                ))}
            </Box>
        </div >
    )
}

export default SpecialServices