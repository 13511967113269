import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './home.css'
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
    //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: '#000520',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    minHeight:'60vh',
    // minHeight: '50vh',
    // [theme.breakpoints.up('xs')]: {
    //     height: '60vh',
    //   },
    color: theme.palette.text.secondary,
}));



const Data = [
    {
        link: 'commercial-cleaning',
        title: 'Commercial',
        subject: 'Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business.',
        image: 'https://images.pexels.com/photos/713297/pexels-photo-713297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
        link: 'residential-cleaning',
        title: 'Residential',
        subject: 'Green Mark King Services always stay on top of creating a healthy and clean home for your family. Your safety is our priority. We always use natural products concerning the health and safety of our clients, their families, pets, and our team, and to protect the environment.',
        image: 'https://images.pexels.com/photos/6758773/pexels-photo-6758773.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
        link: 'janitorial-service',
        title: 'Janitorial',
        subject: 'fast-paced professional team for a wide range of industrial complexes tailored in consonance with the needs of your plant. Our Company has the most certified team of cleaners in the Ontario region providing comprehending cleaning services necessary for large-scale industrial cleaning projects.',
        image: 'https://images.pexels.com/photos/6195129/pexels-photo-6195129.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
        link: 'industrial-cleaning',
        title: 'Industrial',
        subject: 'Green Mark King Services specializes in rendering premium quality services with our fast-paced professional team for a wide range of industrial complexes tailored in consonance with the needs of your plant. Our Company has the most certified team of cleaners in the Ontario region providing comprehending cleaning services necessary for large-scale industrial cleaning projects.',
        image: 'https://images.pexels.com/photos/2569842/pexels-photo-2569842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
]

const Data2 = [
    {
        title: 'Office Cleaning',
        subject:'A Cleaner is responsible for all basic cleaning in and around the facility or office building. This can entail dusting, mopping, sweeping, vacuuming, and cleaning smudges off windows and doors. Ensuring restrooms are cleaned, sanitized, and restocked is another important responsibility of a cleaner.',
        image: 'https://images.pexels.com/photos/7534211/pexels-photo-7534211.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
        title: 'Deep Cleaning',
        subject:'Our team is well-trained and dedicated to ensuring your private space is an entreating visual. With the standard protocol, we focus on cleaning hard-to-reach areas that may not get frequent supervision.',
        image: 'https://images.pexels.com/photos/4099089/pexels-photo-4099089.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
        title: 'Power Washing',
        subject:'Green Mark King provides you with dependable and reliable power washing service across Ontario wide. Our high-pressure and steam-blasting technology can create spectacular external modifications without significant expense to your facility. ',
        image: 'https://thumbs.dreamstime.com/b/house-facade-cleaning-service-man-washing-wall-high-pressure-washer-building-152731742.jpg'
    },
    {
        title: 'Restroom Cleaning',
        subject:'Remove any debris from in and around the toilet and urinals. Place any urinal strainers in a bucket of disinfectant solution. Flush the toilet to make sure it is clear and working properly. Remove all visible soils with a multi-purpose cleaner or cleaner/disinfectant.',
        image: 'https://images.pexels.com/photos/4239113/pexels-photo-4239113.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
]

export default function RowAndColumnSpacing() {

    const [isHover, setIsHover] = React.useState(false)
    const [hoverIndex, setHoverIndex] = React.useState('')

    const hoverHandle = (index) => {
        setHoverIndex(index)
        setIsHover(true)
    }

    return (
        <>
            <Box sx={{ width: '100%', mt: 10, pl: 6, pr: 6 }}>
                <div className=' bg-red-00 pt-7  flex  flex-col justify-center items-center text-center pb-12 '>
                    {/* <h3 className='font-semibold text-[#9AE6B4]  text-2xl '>Our Service</h3> */}
                    <h1 className='text-4xl font-bold py-5 heading-fonts '>Our Services</h1>
                </div>
                <Grid container rowSpacing={9} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {Data?.map((item, index) => (
                        <Grid xs={12} md={6} key={index} >
                            <Item onMouseEnter={() => hoverHandle(index)} onMouseLeave={() => setIsHover(false)} className='service-container' >
                                <Stack display='flex' direction='row' justifyContent='space-between' sx={{ mt: 2, pl: 5, pr: 5 }}>
                                    <Box display='flex' justifyContent='center'>
                                        <Typography  variant='h4' fontWeight={570} sx={{ color: '#9AE6B4', }}><div className='heading-fonts'>{item.title}</div></Typography>
                                    </Box>
                                    {isHover && hoverIndex === index ?
                                        <Box  >
                                            <Link to={item.link}>  <IconButton>
                                                <ArrowForwardIcon sx={{ color: '#FFFFFF' }} fontSize='large' />
                                            </IconButton>   </Link>
                                        </Box> : ''}
                                </Stack>
                                <Box sx={{ mt: 5, pl: 3, pr: 3, pb: 2 }}>
                                    <Typography variant='h6' sx={{ color: '#FFFFFF' }}>{item.subject}</Typography>
                                </Box>
                            </Item>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* special services */}

            <Box sx={{ width: '100%', mt: 10, pl: 6, pr: 6 }}>
                <div className=' bg-red-00 pt-7  flex  flex-col justify-center items-center text-center pb-12 '>
                    {/* <h3 className='font-semibold text-[#9AE6B4]  text-2xl '>Our Service</h3> */}
                    <h1 className='text-4xl font-bold py-5 heading-fonts '>Our Special Services</h1>
                </div>
                <Grid container rowSpacing={9} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {Data2?.map((item, index) => (
                        <Grid xs={12} md={6} key={index} >
                            <Item onMouseEnter={() => hoverHandle(index)} onMouseLeave={() => setIsHover(false)} className='service-container' >
                                <Stack display='flex' direction='row' justifyContent='space-between' sx={{ mt: 2, pl: 5, pr: 5 }}>
                                    <Box>
                                        <Typography variant='h4' fontWeight={570} sx={{ color: '#9AE6B4', }}><div className='heading-fonts'>{item.title}</div></Typography>
                                    </Box>
                                    {isHover && hoverIndex === index ?
                                        <Box  >
                                            <Link to='Special-service'>  <IconButton>
                                                <ArrowForwardIcon sx={{ color: '#FFFFFF' }} fontSize='large' />
                                            </IconButton>   </Link>
                                        </Box> : ''}
                                </Stack>
                                <Box sx={{ mt: 5, pl: 2, pr: 2 }}>
                                    <Typography variant='h6' sx={{ color: '#FFFFFF' }}>{item.subject}</Typography>
                                </Box>
                            </Item>
                        </Grid>
                    ))}
                </Grid>
            </Box>

        </>
    );
}