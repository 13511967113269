import React, { useEffect } from 'react'
import Link, { useNavigate } from 'react-router-dom'
// import commercial from '../../assets/Images/Commercial.jpg'
// import Residential from '../../assets/Images/Residential.jpg'
// import Janitorial from '../../assets/Images/Janitorial.jpg'
// import Industrial from '../../assets/Images/Industrial.jpg'


const Data = [
   {
      link:'commercial-cleaning',
      title: 'Commercial',
      image: 'https://images.pexels.com/photos/713297/pexels-photo-713297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
   },
   {
      link:'residential-cleaning',
      title: 'Residential',
      image: 'https://images.pexels.com/photos/6758773/pexels-photo-6758773.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' 
     },
   {
      link:'janitorial-service',
      title: 'Janitorial',
      image: 'https://images.pexels.com/photos/6195129/pexels-photo-6195129.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
      },
   {
      link:'industrial-cleaning',
      title: 'Industrial',
      image: 'https://images.pexels.com/photos/2569842/pexels-photo-2569842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'  
   },
]

const Data2 = [
   {
      title: 'Office Cleaning',
      image: 'https://images.pexels.com/photos/7534211/pexels-photo-7534211.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
   },
   {
      title: 'Deep Cleaning',
      image: 'https://images.pexels.com/photos/4099089/pexels-photo-4099089.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
   },
   {
      title: 'Power Washing',
      image: 'https://thumbs.dreamstime.com/b/house-facade-cleaning-service-man-washing-wall-high-pressure-washer-building-152731742.jpg'
   },
   {
      title: 'Restroom Cleaning',
      image: 'https://images.pexels.com/photos/4239113/pexels-photo-4239113.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
   },
]

const ServiceProvider = () => {
   const navigate = useNavigate()

   // useEffect(() => {
     
   // }, [])
   
   return (
      <>
         <div className=' bg-red-00 pt-10  flex  flex-col justify-center items-center text-center pb-12 '>
            <h3 className='font-semibold text-[#9AE6B4]  text-2xl '>Our Service</h3>
            <h1 className='text-3xl font-bold py-5 '>We Provide Service</h1>

            {/* IMAGE */}

            <div className='container mx-auto space-y-2 lg:space-y-0 lg:gap-2 lg:grid lg:grid-cols-4'>
               {Data.map((item, index) => (
                 <div onClick={()=>navigate(item.link)} key={index} className='relative'>
                     <div className='bg-red-00  '>
                        <img className='lg:h-[300px] w-full px-4  md:h-[400px] h-[300px] '
                           src={item.image} />
                        {/* <div className='bg-green-400 w-full h-full '>
              <h3 className='absolute text-xl  md:text-3xl text-amber-400 bottom-4 left-1/2 -translate-x-1/2'>Bottom text</h3>
            </div> */}
                        <div className="absolute bottom-0 left-4 right-4  py-2 bg-gray-800 opacity-70 ">
                           <h3 className="text-xl text-white font-bold   py-3">
                              {item.title}</h3>
                           {/* <p className="mt-2 text-sm text-gray-300 ">Some description text. Some dummy text here. Welcome to KindaCode.com</p> */}
                        </div>
                     </div>
                  </div>
               ))}

            </div>
         </div>

         {/* IMAGE 2 */}


         <div className=' bg-red-00 pt-10  flex  flex-col justify-center items-center text-center pb-12 '>
            <h1 className='text-3xl font-bold py-5 '>Our Special Service</h1>
            <div className='container mx-auto space-y-2 lg:space-y-0 lg:gap-2 lg:grid lg:grid-cols-4'>
               {Data2.map((item, index) => (
                  <div onClick={()=>navigate('Special-service')} key={index} className='relative'>
                     <div className='bg-red-00  '>
                        <img className='lg:h-[300px] w-full px-4  md:h-[400px] h-[300px] '
                           src={item.image} />
                        {/* <div className='bg-green-400 w-full h-full '>
              <h3 className='absolute text-xl  md:text-3xl text-amber-400 bottom-4 left-1/2 -translate-x-1/2'>Bottom text</h3>
            </div> */}
                        <div className="absolute bottom-0 left-4 right-4  py-2 bg-gray-800 opacity-70 ">
                           <h3 className="text-xl text-white font-bold   py-3">
                              {item.title}</h3>
                           {/* <p className="mt-2 text-sm text-gray-300 ">Some description text. Some dummy text here. Welcome to KindaCode.com</p> */}
                        </div>
                     </div>
                  </div>
               ))}


            </div>
            
         </div>
      </>

   )
}

export default ServiceProvider