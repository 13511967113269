import { useEffect } from 'react';
import { useLocation } from "react-router";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import Commercial from './pages/Services/Commercial/Commercial';
import Industrial from './pages/Services/Industrial/Industrial';
import Janitorial from './pages/Services/Janitorial/Janitorial';
import Residential from './pages/Services/Residential/Residential';
import SpecialServices from './pages/SpecialServices/SpecialServices';
import ScrollToTop from './ScrollToTop';
import './fonts/GT-Super-Display-Bold.ttf'


function App() {

 
  
  return (
    <div className="App">
       <Router>
         <ScrollToTop>
        <Navbar/>
       
         <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route exact path='contact-us' element={<Contact/>} />
            <Route exact path='about-us' element={<About/>} />
            <Route exact path='/commercial-cleaning' element={<Commercial/>} />
            <Route exact path='residential-cleaning' element={<Residential/>} />
            <Route exact path='industrial-cleaning' element={<Industrial/>} />
            <Route exact path='janitorial-service' element={<Janitorial/>} />
            <Route exact path='Special-service' element={<SpecialServices/>} />
         </Routes> 
           <Footer/>
          </ScrollToTop>
       </Router>
             
    </div>
  );
}

export default App;
