import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Container, Typography } from '@mui/material';
import MovingText from 'react-moving-text'
import './home.css'
import Standards from '../../assets/icons/Standards.png'
import customer from '../../assets/icons/customer.png'
import guaranteed from '../../assets/icons/guaranteed.png'
import { Stack } from '@mui/system';

function ChooseUs() {
    return (
        <div>
            <Box sx={{ mt: 6 }}>
                <Grid item md={8} sm={12} xs={12}>
                    <Container maxWidth="lg" sx={{ mt: 10 }}>
                        <Box display='flex' justifyContent='center' sx={{
                            maxHeight: '50vh', width: '100%', 
                            // backgroundColor: '#000520'
                        }}>
                            <Typography sx={{ pt: 6, pb: 6, ml: 1, color: '#000520' }} fontWeight={600} variant='h3'>
                                <div className='heading-fonts'>WHY CHOOSE US</div>
                            </Typography>
                        </Box>
                    </Container>
                </Grid>
            </Box>
            <Box sx={{ mt: 8, mr: 5, ml: 5 }}>
                <Grid item md={12} sm={12} xs={2}>
                    <Container maxWidth="lg"  display='flex'  sx={{ width: '100%',pl:1 }}>
                        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                            <Stack display='flex' direction='row' spacing={1} justifyContent='flex-start'>
                                <Box sx={{ width: '90px',display:{xs:'none',md:'block',sm:'block'} }}><img className='choose-us-icons' src={Standards} /></Box>
                                <Typography variant='h3' sx={{color:'#9AE6B4'}} fontWeight='bold'><div className='heading-fonts'>Exceptional Standards</div></Typography>
                            </Stack>
                        </MovingText>
                    </Container>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ maxHeight: '200vh', mt: 7 }}>
                <Box sx={{ maxHeight: '200vh' }} >
                    <Typography fontWeight={400} variant='h5' sx={{opacity:.5}}>
                        We ‘re committed to providing our clients with the highest calibre of service. Our cleaners employ top-tier industrial cleaning equipment and supplies and keep the interior spaces of the buildings and workplaces pleasant, spotlessly clean, and hygienic finish, we hope to go above and beyond the expectations of our clients.                     </Typography>
                </Box>
            </Container>
            <Box sx={{ mt: 8, ml: 6, mr: 6 }}>
                <Grid item md={12} sm={12} xs={2}>
                    <Container maxWidth="lg"  display='flex'  sx={{ width: '100%',pl:1 }}>
                        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                            <Stack display='flex' direction='row' spacing={1} justifyContent='flex-start'>
                                <Box sx={{ width: '70px',display:{xs:'none',md:'block',sm:'block'} }} ><img className='choose-us-icons' src={customer} /></Box>
                                <Typography variant='h3' sx={{color:'#9AE6B4'}} fontWeight='bold'><div className='heading-fonts'>Strong Customer Focus</div></Typography>
                            </Stack>
                        </MovingText>
                    </Container>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ maxHeight: '200vh', mt: 7 }}>
                <Box sx={{ maxHeight: '200vh' }} >
                    <Typography fontWeight={400} variant='h5' sx={{opacity:.5}}>
                        Green Mark King hands you the gift of time by providing us with your preferred days and hours, and we'll put out a schedule that stays under your spending limit. A high level of client satisfaction is ensured through routine visits and inspections of our contracts, which guarantee the finest attention to detail. We are also Licensed, bonded, and Insured for your protection.                    </Typography>
                </Box>
            </Container>
            <Box sx={{ mt: 8, ml: 6, mr: 6 }}>
                <Grid item md={12} sm={12} xs={2}>
                    {/* <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                        <Box display='flex' justifyContent='center'>
                            <Typography variant='h3' fontWeight='bold'><div className='heading-fonts'>Our guarantee of Service</div></Typography>
                        </Box>
                    </MovingText> */}
                    <Container maxWidth="lg" display='flex'  sx={{ width: '100%',pl:1 }}>
                        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                            <Stack display='flex' direction='row' spacing={1} justifyContent='flex-start' >
                                <Box sx={{ width: '70px' ,display:{xs:'none',md:'block',sm:'block'} }} ><img className='choose-us-icons' src={guaranteed} /></Box>
                                <Typography variant='h3' sx={{color:'#9AE6B4',}} fontWeight='bold'><div className='heading-fonts'>Our guarantee of Service</div></Typography>
                            </Stack>
                        </MovingText>
                    </Container>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ maxHeight: '200vh', mt: 7, pb: 12 }}>
                <Box sx={{ maxHeight: '200vh' }} >
                    <Typography fontWeight={400} variant='h5' sx={{opacity:.5}}>
                        We offer a 100% Guarantee of Service because we are confident you will love the premium quality of our Green Mark King cleaning service. We give you a space of gratifying and clean environment with top-notch service. If for any reason you are not happy with us, please let us know within 2 business days, our team will be at your doorstep to make up the situation to your contentment. Our confidence rests on your satisfaction.                    </Typography>
                </Box>
            </Container>
        </div>
    )
}

export default ChooseUs