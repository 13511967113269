import { Box, Container, Grid, Typography, styled } from '@mui/material'
import React from 'react'
import Paper from '@mui/material/Paper';
import header_jacks_logo_desktop_2x from '../../../assets/clientLogos/00_header_jacks_logo_desktop_2x.webp'
import image from '../../../assets/clientLogos/image.webp'
import scaddabush_logo from '../../../assets/clientLogos/scaddabush_logo4-1.webp'
import './client.css'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const logos = [
  {
    image: header_jacks_logo_desktop_2x
  },
  {
    image: image
  },
  {
    image: scaddabush_logo
  }
]

const OurClients = () => {
  return (
    <>
      <Box mt={2}>
        <Box>
          <div className=' bg-red-00 pt-7  flex  flex-col justify-center items-center text-center pb-12 '>
            {/* <h3 className='font-semibold text-[#9AE6B4]  text-2xl '>Our Service</h3> */}
            <h1 className='text-4xl font-backgroundColor py-5 heading-fonts'>Our Clients</h1>
          </div>
        </Box>
        <Container maxWidth="xl">
          <Box sx={{ bgcolor: 'transparent', height: '28vh', }} >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {logos && logos.map((item, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    {/* <Item>xs=2</Item> */}
                    <Box display='flex' justifyContent='center' alignItems='center' sx={{ width: "100%", height: '28vh', bgcolor: "transparent" }}>
                      <img className='client_logo' src={item?.image} alt='client logos' />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default OurClients