import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { Container, createTheme, Divider, Grid, Stack, Typography } from '@mui/material';
import image from '../../../assets/Images/Commercial.jpg'
import { ThemeProvider } from '@mui/system';
import MovingText from 'react-moving-text'
import { CgShapeCircle } from 'react-icons/cg';
import { BsRecordCircle } from 'react-icons/bs'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';


const theme = createTheme();

theme.typography.h7 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
};

const Data = [
  {
    title1: 'Home Cleaning',
    title2: 'Dusting and detailing of all surface and fixtures',
    image1: 'https://images.pexels.com/photos/5591454/pexels-photo-5591454.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    image2: 'https://images.pexels.com/photos/4108712/pexels-photo-4108712.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    content1: 'Your home will be back to its immaculate best once we join hands. Our cleaning service covers window cleaning, laundry, washroom cleaning, sanitization, and much more. Give your keys to us and we will provide you with a fit and jovial home.',
    content2: 'Green Mark King gets to all the corners of your to maintain dust-free and gleaming finishes on all the surfaces. We leave no trace of dust in any of the areas.    ',
  },
  {
    title1: 'Trash removal',
    title2: 'Vacuuming',
    image1: 'https://images.pexels.com/photos/7512785/pexels-photo-7512785.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    image2: 'https://images.pexels.com/photos/6195951/pexels-photo-6195951.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    content1: 'We understand your commitment and provide you with a customized cleaning ploy that is conforming to your ease. We remove all the garbage left behind and proffer you a tranquil air within your quarter.',
    content2: 'We vacuum all areas in your home including carpets, hallways, and balconies achieving antiseptic domain with our latest equipment.',
  },
  {
    title1: 'Sweeping and mopping',
    title2: 'Restroom sanitisation ',
    image1: 'https://images.pexels.com/photos/5591861/pexels-photo-5591861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    image2: 'https://images.pexels.com/photos/4098778/pexels-photo-4098778.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    content1: 'Green Mark King avails you a home where you can reminisce the novelty of our 100% biodegradable products, making them environmentally safe.',
    content2: 'The cleanliness of restrooms has a direct impact on the overall impression of any facility. We employ effective and efficient sanitization programs for our clients eliminating the spread of germs. We take care of the quality of restroom hygiene on the overall perception with effective restroom supplies, no stains or discoloration on fixtures/dispensers, and floors free of litter',
  },
]

export default function Residential() {
  const slides = [

    {
      url: 'https://images.pexels.com/photos/6758771/pexels-photo-6758771.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: "Residential Cleaning",
      subTitle: " Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
      buttonTitle: "Get a quate"
    },
    {
      url: 'https://images.pexels.com/photos/5998040/pexels-photo-5998040.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: "Residential Cleaning",
      subTitle: "  Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
      buttonTitle: "Get a quate"
    },
    {
      url: 'https://images.pexels.com/photos/6316055/pexels-photo-6316055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: "Residential Cleaning",
      subTitle: " Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres",
      buttonTitle: "Get a quate"
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0)

  const prevSlide = () => {
    const idFirstSlide = currentIndex === 0;
    const newIndex = idFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex)
  }
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex)
  }
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex)
  }

  return (
    <div>
      {/* <Box sx={{ mt: 8, ml: 6, mr: 6 }}>

        <Grid item md={12} sm={12} xs={2}>
          <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
            <Box display='flex' justifyContent='center'>
              <Typography variant='h3' fontWeight='bold'><div className='heading-fonts'>RESIDENTIAL CLEANING</div></Typography>
            </Box>
          </MovingText>
        </Grid>
      </Box> */}
      <Box sx={{ ml: 2, mr: 2, maxHeight: '100%' }} >

        {/* //CAROUSAL// */}

        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
          <div className='max-w-[1900px] md:h-auto h-[400px] w-full group relative '>
            <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
              className=' w-full h-[500px] md:h-[632px] xs:h-[450px] rouned-2xl bg-center bg-cover duration-500 
              flex  flex-col justify-center items-center text-center  '>
              <div className='md:space-y-10 space-y-4'>
                <div className='text-white md:text-6xl text-3xl font-bold md:py-0 py-7 heading-fonts'>
                        <MovingText
                            type="fadeInFromLeft"
                            duration="1000ms"
                            delay="0s"
                            direction="normal"
                            timing="ease"
                            iteration="1"
                            fillMode="none">{slides[currentIndex]?.title}</MovingText>
                    </div>
                    {/* <div className='text-white md:text-2xl text-1sm font-medium md:py-0 mt-10 md:mt-0 '>
                        <MovingText
                            type="fadeInFromRight"
                            duration="1000ms"
                            delay="0s"
                            direction="normal"
                            timing="ease"
                            iteration="1"
                            fillMode="none">{slides[currentIndex]?.subTitle}</MovingText>
                    </div> */}
                <div className='text-white '>
                  <MovingText
                    type="fadeInFromBottom"
                    duration="1000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="1"
                    fillMode="none">
                    {/* <button type="button" className="inline-block md:px-10 px-4  md:py-4 py-2 border-2 border-white text-black font-medium 
                        leading-tight uppercase rounded hover:bg-white hover:bg-opacity-1 hover:text-black transition-all
                       duration-300 ease-in focus:outline-none focus:ring-0  
                      t  md:text-xl  ">
                                {slides[currentIndex]?.buttonTitle}
                            </button> */}
                  </MovingText>
                </div>

                <div>
                  <Box sx={{ pt: 15 }}>
                    <Stack display='flex' direction='row' spacing={5} justifyContent='center' alignItems='flex-end'>
                      {slides.map((slide, slideIndex) => (
                        <div className=''>
                          <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className='text-gray-100 cursor-pointer text-2xl md:text-4xl '>

                            {slideIndex === currentIndex ? <BsRecordCircle /> : <CgShapeCircle />}

                          </div>
                        </div>


                      ))}
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>

            {/* left arrow */}

            {/* <div className='hidden group-hover:block absolute  top-[40%] -translate-x-0 translate-y-[50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
              <BsChevronCompactLeft onClick={prevSlide} size={30} />
            </div> */}

            {/* right arrow */}

            {/* <div className='hidden group-hover:block absolute top-[40%] -translate-x-0 translate-y-[50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
              <BsChevronCompactRight onClick={nextSlide} size={30} />
            </div> */}
          </div>

        </MovingText>

        {/* //CAROUSAL// */}

        <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '20vh', mt: 14, backgroundColor: 'black', display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Professional,veted cleaners</Typography>
              </Grid>,mt:5
              <Grid item xs>
                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Friendly customer support</Typography>
              </Grid>
              <Grid item xs>
                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Bonded and insured</Typography>
              </Grid>
              <Grid item xs>
                <Typography sx={{ color: '#FFFFFF', mt: 5, ml: 6 }}>Recurring discounts</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        
        <Container maxWidth="lg" sx={{ mt: 10, maxHeight: '200vh', mt: 17 }}>
          <Box sx={{ maxHeight: '200vh' }} >
            <Typography fontWeight={400} variant='h6' sx={{opacity:.6}}>
              Green Mark King Services always stay on top of creating a healthy and clean home for
              your family. Your safety is our priority. We always use natural products concerning
              the health and safety of our clients, their families, pets, and our team, and to
              protect the environment. As residential cleaning professionals, we always use standard
              equipment to clean every corner of your home employing 100% biodegradable products,
              making them environmentally safe. We customize the cleaning to your preferred hours
              and deliver a 100% Guarantee of Service.We frequently check our personnel for
              any illness-related symptoms. Before beginning any work, our professional staff
              puts on the appropriate personal protective equipment for both your and our safety.
            </Typography>
          </Box>
        </Container>

        <Box sx={{ mt: 6 }}>
          <Grid item md={8} sm={12} xs={12}>
            <Container maxWidth="lg" sx={{ mt: 10 }}>
              <Box display='flex' justifyContent='center' sx={{ maxHeight: '50vh', pl: 6, pr: 6, width: '100%', backgroundColor: '#000520' }}>
                <Typography sx={{ pt: 6, pb: 6, color: '#FFFFFF' }} fontWeight={600} variant='h5'>
                  Choose Green Mark King services to live in a pristine environment at each moment including these standard elements                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        {Data.map((item, index) => (
          <Box key={index}>
            <Box minHeight='60vh' sx={{ mt: 12, boxShadow: 3,borderRadius:5  }} display='flex' justifyContent='center' alignItems='center'>
              <Box sx={{ mt: 2, maxHeight: '100%', ml: 3, mr: 3 }} >
                <Grid container spacing={2}>
                  <Grid item md={4} sm={12} xs={12}>
                    <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                      <Box
                        sx={{
                          width: '100%',
                          height: 300,
                        }}
                      >
                        <img className='Sub-Image' src={item.image1} />
                      </Box>
                    </MovingText>
                  </Grid>
                  <Grid item md={8} xs={12} sm={12}>
                    <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                      <Box
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100vh'
                        }}
                      >
                        <Typography sx={{ mt: 2, ml: 2, }} fontWeight={600} variant="h3" gutterBottom>
                          <div className='heading-fonts text-[#9AE6B4]'>{item.title1}</div>
                        </Typography>
                        <Box sx={{ height: '100%',ml: 2, mr: 2 }}>
                          <ThemeProvider theme={theme}>
                            <Typography variant="h7" sx={{  maxWidth: '100%',opacity:.6 }}  >
                              {item.content1}
                              {/* Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres */}
                            </Typography>

                          </ThemeProvider>
                        </Box>
                      </Box>
                    </MovingText>
                  </Grid>
                </Grid>
              </Box>


            </Box>
            <Box sx={{ mt: 8, minHeight: '60vh', boxShadow: 3 ,borderRadius:5 }} >
              <Box sx={{ mt: 2, maxHeight: '100%', ml: 3, mr: 3 }}>
                <Grid container spacing={2}>
                  <Grid item md={8} xs={12} sm={12}>
                    <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                      <Box
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100vh'
                        }}
                      >
                        <Typography sx={{ mt: 2, ml: 2, }} fontWeight={600} variant="h3" gutterBottom>
                          <div className='heading-fonts text-[#9AE6B4]'>{item.title2}</div>
                        </Typography>
                        <Box sx={{ height: '100%',ml: 2, mr: 2 }}>
                          <ThemeProvider theme={theme}>
                            <Typography variant="h7" sx={{ maxWidth: '100%',opacity:.6 }}  >
                              {item.content2}
                              {/* Green Mark King deliver professional commercial cleaning services to businesses of all sizes across Toronto and Ontario wide. Our exceptional cleaning standards render you a sterile clean facility for your business. We have expanded over the years to serve a wide range of sectors, including restaurants, food processing plants, multi-tenant buildings, post construction sites, large and small offices. Experience revitalizing freshness with us and embrace the simplicity within your commercial spheres */}
                            </Typography>
                          </ThemeProvider>
                        </Box>
                      </Box>
                    </MovingText>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
                      <Box
                        sx={{
                          width: '100%',
                          height: 300,
                        }}
                      >
                        <img className='Sub-Image' src={item.image2} />
                      </Box>
                    </MovingText>
                  </Grid>
                </Grid>
                {/* <Divider sx={{ pt: 10 }} /> */}
              </Box>
            </Box>
          </Box>

        ))}
      </Box>
    </div >
  )
}
