import { Box } from '@mui/material'
import React from 'react'
import MovingText from 'react-moving-text'
import Locations from '../Home/Locations'


function About() {
  return (
    <div>
       
      <div className='grid md:grid-cols-2 py-10 md:py-16 gap-10'>
      <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
        <div className='bg-geen-500 flex justify-center font-semibold items-center text-center text-5xl heading-fonts text-[#9AE6B4]'>
          Green Mark King is a professional cleaning service company
        </div>
        <div className='bg-geen-500 flex justify-center font-semibold items-center text-center text-5xl mt-20 md:px-16'>
        
        <img src='https://images.pexels.com/photos/6196223/pexels-photo-6196223.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
          className="  w-[40] h-[40] object-cover 
         rouned-2xl bg-center bg-cove duration-500 flex flex-col justify-center items-center text-center about-image"
          alt="" />
    
        </div>
        </MovingText>
        <MovingText type="fadeInFromBottom" duration="1000ms" delay="0s" direction="normal" timing="ease" iteration="1" fillMode="none">
        <div className='bg-rd-500 px-14 text-xl opacity-60'>

          We are a national company designed for you based in entire Toronto and  GTA areas
          specialized in providing cleaning services over 20 years of experience. We excel our
          expertise by delivering premium quality of cleaning services in whatever work you
          entrust with us. Endow your sphere to Green Mark King and we will imprint our
          signature fingerprints on your line of business to much appreciation.
          <br></br>
          <br></br>

          We are one among the top cleaning Companies in Ontario one could get for offering
          a team of dedicated professional team available under your convenient schedule.
          Green Mark King covers a whole spectrum of janitorial services including commercial
          cleaning, residential cleaning, industrial cleaning, post construction cleaning and
          much more. We also overlook in services such as deep cleaning, floor waxing, power
          washing, carpet cleaning. We readily customise cleaning schedule to your ease and
          are flexible in our service.



        </div>
        </MovingText>

      </div>

      {/* IMAGE */}

      <div>
        <img src='https://images.pexels.com/photos/4239113/pexels-photo-4239113.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
          className="  w-full h-[500px] object-cover
         rouned-2xl bg-center bg-cove duration-500 flex flex-col justify-center items-center text-center"
          alt="" />
      </div>

      <hr className="md:my-24 my-20 h-px mx-16 bg-gray-400 border-0 dark:bg-gray-900" />

      <div className='grid md:grid-cols-2 md:py-06 gap-10'>
        <div className='bg-geen-500 flex justify-center items-center text-center text-5xl font-bold heading-fonts text-[#9AE6B4] '>
          Together we are strong
        </div>
        <div className='bg-rd-500 px-16  text-xl opacity-60'>

          Green Mark King can guarantee you a 100% customer satisfaction of service and this
          is our paramount to success. With our knowledge and experience we stand top for our
          high standards and professional integrity. We meet our clients with honesty and ethics
          and our relationships are build on accountability, trust and performance. We always be
          your loyal partner looking forward to earn your business.
          <br></br>
          <br></br>
          Keep you space clean and hygiene with Green Mark King.

        </div>
      </div>
      <Box sx={{display:{xs:'block',sm:'block',md:'block',lg:'block',},mt:5}}>
                <Locations />
            </Box>

    </div>
  )
}

export default About