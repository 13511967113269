import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Stack, Typography } from '@mui/material';
import {LocationList} from './LocationList';


function Locations() {
    return (
        <div>
            <Box>
                <div className=' bg-red-00 pt-7  flex  flex-col justify-center items-center text-center pb-12 '>
                    {/* <h3 className='font-semibold text-[#9AE6B4]  text-2xl '>Our Service</h3> */}
                    <h1 className='text-4xl font-backgroundColor py-5 heading-fonts'>Service Locations</h1>
                </div>
                <Box>
                    <Container maxWidth="lg">
                        <Box display='flex' justifyContent='center' sx={{ bgcolor:'#000520',  maxHeight: '300vh',width:'100%' }} >

                            <Box sx={{ flexGrow: 1,pt:5,pl:10,mb:5 }}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {LocationList?.map((item, index) => (
                                    <Grid item xs={6} sm={4} md={4} >
                                        <Stack display='flex' direction='row'  spacing={2}>
                                            <Box>{item.icon}</Box>
                                            <Box><Typography sx={{color:'#9AE6B4'}}>{item.place}</Typography></Box>
                                        </Stack>
                                    </Grid>
                                      ))} 
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </div>
    )
}

export default Locations