import React from 'react'

const Button = ({ data }) => {
    // console.log("props...",props.children)
    return (

        <div className=''>
            <button className='bg-[#000520] text-white shadow-xl  py-2 px-6 rounded md:ml-8 hover:bg-[#9AE6B4] 
        uration-500   '>
                <div className='flex'>
                   {data == '+1 (416) 272-9799' ? 
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                        className="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                        </path>
                    </svg>:
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"  className="w-6 mr-2" id="Phone">
                      <path d="M31 2H15c-2.76 0-5 2.24-5 5v34c0 2.76 2.24 5 5 5h16c2.76 0 5-2.24 5-5V7c0-2.76-2.24-5-5-5zm-8 42c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm9-8H14V8h18v28z" fill="#ffffff" class="color000000 svgShape"></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>}
                    <a href={`tel:${data}`}>{data}</a>
                </div>
            </button>
        </div>

    )
}

export default Button